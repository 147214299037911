<template>
    <div class="ku-bbs-publish-article">
        <div class="title-box">
            <span class="title-bold" @click="goCreateCenter">创作中心</span>
            <span class="draft" @click="goCreateCenterDraft">
                草稿箱
                <i v-if="token">（{{ postDraft }}）</i>
                <i v-else>（{{ 0 }}）</i>
            </span>
        </div>
        <div class="option-box">
            <div class="item" @click="sendData">
                <img src="@/assets/icons/dongtai.svg" alt="发动态" />
                <span>发动态</span>
            </div>
            <div class="item" @click="goWriteArticle">
                <img src="@/assets/icons/wenzhang.svg" alt="发文章" />
                <span>写文章</span>
            </div>
        </div>
        <div class="ku-bbs-publish-total" v-if="this.token" @click="goToMessageCenter">
            <div class="ku-bbs-publish-zan button">
                累计获赞
                <span class="ku-bbs-publish-num" :class="unreadCount.unReadLikeMsg > 0 ? 'dot' : ''">
                    {{ $num(postLike) }}
                </span>
            </div>
            <div class="ku-bbs-publish-zan button">
                累计获收藏
                <span class="ku-bbs-publish-num" :class="unreadCount.unReadCollectMsg > 0 ? 'dot' : ''">
                    {{ $num(postCollect) }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    name: "ku-artilce-create",
    props: {
        postDraft: {
            type: Number,
            default: 0,
        },
        postLike: {
            type: Number,
            default: 0,
        },
        postCollect: {
            type: Number,
            default: 0,
        },
        unreadCount: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    computed: {
        ...mapGetters(["token"]),
    },
    methods: {
        // 去写文章
        goWriteArticle() {
            if (this.token) {
                // 需要判断是否登录
                this.$router.push({
                    path: "/fullwrite",
                    query: {
                        time: new Date().getTime(),
                    },
                })
            } else {
                this.$store.commit("SET_SHOWLOGIN", true)
            }
        },
        // 发动态
        sendData() {
            if (this.token) {
                this.$router.push({
                    path: "/create/index/publishtrend",
                    query: {
                        currentIndex: "item_0_0",
                    },
                })
            } else {
                this.$store.commit("SET_SHOWLOGIN", true)
            }
        },
        // 去创作中心
        goCreateCenter() {
            if (this.token) {
                this.$router.push({
                    path: "/create/index/articlelist",
                })
            } else {
                this.$store.commit("SET_SHOWLOGIN", true)
            }
        },
        goCreateCenterDraft() {
            if (this.token) {
                this.$router.push({
                    path: "/create/index/articlelist",
                    query: {
                        current: 2,
                    },
                })
            } else {
                this.$store.commit("SET_SHOWLOGIN", true)
            }
        },
        goToMessageCenter() {
            if (this.token) {
                this.$router.push({
                    path: "/create/index/messagelist",
                    query: {
                        current: 3,
                    },
                })
            } else {
                this.$store.commit("SET_SHOWLOGIN", true)
            }
        },
    },
}
</script>

<style lang="scss">
.ku-bbs-publish-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 24px 14px;
    .ku-bbs-publish-zan {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
    }
    .ku-bbs-publish-num {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        &.dot {
            position: relative;
            &::after {
                position: absolute;
                right: -3px;
                top: 0;
                content: "";
                width: 4px;
                height: 4px;
                background: #d00108;
                display: block;
                border-radius: 50%;
            }
        }
    }
}
.ku-bbs-publish-article {
    width: 280px;
    max-height: 206px;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 14px;
    position: relative;
    background: #fff;
    box-sizing: border-box;
    background: linear-gradient(176deg, rgba(36, 198, 139, 0.17) 0%, #fff 40%);

    .title-box {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title-bold {
            font-size: 16px;
            font-weight: 600;
            color: #222222;
            cursor: pointer;
        }

        .draft {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            cursor: pointer;

            i {
                font-style: normal;
            }
        }
    }

    .option-box {
        display: flex;
        justify-content: space-around;
        height: 110px;

        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &.gray {
                filter: grayscale(1);
            }

            span {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #24c68b;
                line-height: 20px;
                margin-top: 6px;
            }
        }
    }

    .total-box {
        display: flex;
        justify-content: space-between;
        padding: 0 16px;

        span {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
            cursor: pointer;

            i {
                height: 20px;
                font-size: 14px;
                font-weight: 600;
                color: #000000;
                line-height: 20px;
                margin-left: 6px;
                font-style: normal;
                position: relative;

                &.active::before {
                    position: absolute;
                    right: -6px;
                    top: 4px;
                    content: "";
                    width: 4px;
                    height: 4px;
                    background: #d00108;
                    display: block;
                    border-radius: 50%;
                }
            }
        }
    }

    img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
    }
}
</style>
