<template>
    <div class="ku-creative-center-page">
        <!-- 菜单 -->
        <div
            class="left"
            :class="{
                'reset-create-height': resetHeight,
            }"
        >
            <div class="sidebar">
                <!-- <div class="write-btn" @click="goFullWrite">
                    <img src="../../assets/icons/wrtie-article.png" alt="" srcset="" />
                    <span class="success">写文章</span>
                </div> -->
                <div class="item-box" v-for="(item, index) in navList" :key="`item_${index}`">
                    <div class="title">
                        <img :src="item.src" alt="" />
                        <span>{{ item.title }}</span>
                    </div>
                    <div
                        :class="currentIndex == `item_${index}_${subIndex}` ? 'item active' : 'item'"
                        v-for="(subItem, subIndex) in item.list"
                        :key="`item_${index}_${subIndex}`"
                        @click="go2page(index, subIndex, subItem.path)"
                    >
                        <div>
                            <i :class="subItem.icon"></i>
                            {{ subItem.title }}
                            <div class="dot" v-if="subItem.title == '消息管理' && msgCounts > 0"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 内容区域 -->
        <div
            class="right"
            :class="{
                'reset-create-height': resetHeight,
            }"
        >
            <router-view :key="`routerview_${currentIndex}`" />
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex"
import icon_0 from "../../assets/icons/chuangzuo.png"
import icon_1 from "../../assets/icons/glzx.png"
// import { getUserUnreadMsgNum } from "@/api/user"
export default {
    name: "kuCreativeCenter",
    data() {
        return {
            keepAliveList: ["/create/index/articlelist"],
            currentIndex: "item_1_1",
            navList: [
                {
                    title: "创作中心",
                    src: icon_0,
                    list: [
                        {
                            title: "发动态",
                            path: "publishtrend",
                            icon: "el-icon-edit-no",
                        },
                        {
                            title: "写文章",
                            path: "/fullwrite",
                            icon: "el-icon-edit-no",
                        },
                    ],
                },
                {
                    title: "管理中心",
                    src: icon_1,
                    list: [
                        {
                            title: "消息管理",
                            path: "messagelist",
                            show: false,
                            icon: "el-icon-circle",
                        },
                        {
                            title: "内容管理",
                            path: "articlelist",
                            show: false,
                            icon: "el-icon-circle",
                        },
                    ],
                },
            ],
            resetHeight: true,
            msgCount: 0,
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (to.name == "articleList") {
                vm.currentIndex = "item_1_1"
            }
            if (to.name == "messagelist") {
                vm.currentIndex = "item_1_0"
            }
            if (to.name == "publishtrend") {
                vm.currentIndex = "item_0_0"
            }
        })
    },
    beforeRouteLeave(to, from, next) {
        next(() => {
            console.log(window.scrollY)
        })
    },
    computed: {
        ...mapGetters(["count"]),
        msgCounts() {
            let { msgCount = 0, commentMsgCount = 0, likeMsgCount = 0 } = this.count
            return msgCount + commentMsgCount + likeMsgCount
        },
    },
    watch: {},
    created() {
        this.currentIndex = this.$route.query.currentIndex || "item_1_1"
    },
    methods: {
        go2page(index, subIndex, path) {
            this.currentIndex = `item_${index}_${subIndex}`
            console.log("currentIndex", this.currentIndex)
            this.$forceUpdate()
            sessionStorage.removeItem("pageData")
            if (path == "/fullwrite") {
                this.$router.push({
                    path: "/fullwrite",
                    query: {
                        time: new Date().getTime(),
                    },
                })
            } else {
                path &&
                    this.$router.push({
                        path: path,
                        query: {
                            currentIndex: this.currentIndex,
                        },
                    })
            }
        },
        goFullWrite() {
            this.$router.push({
                path: "/fullwrite",
                query: {
                    time: new Date().getTime(),
                },
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.ku-creative-center-page {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    //min-height: calc(100vh - 64px);
    padding-top: 30px;
    .reset-create-height {
        min-height: calc(100vh - 140px) !important;
    }
    .left {
        //min-height: calc(100vh - 64px);
        min-height: calc(100vh - 140px);
        margin-right: 20px;
        background: #fff;
        border-radius: 10px 10px 0px 0px;
        border-bottom: 20px solid #f7f7f7;
        box-sizing: border-box;
        > div {
            position: sticky;
            top: 0px;
            padding: 0 14px;
            width: 180px;
            background: #fff;
            box-sizing: border-box;
            border-radius: 10px 10px 0px 0px;
            padding-top: 15px;
            background: linear-gradient(
                160deg,
                rgba(36, 198, 139, 0.1) 0%,
                rgba(255, 255, 255) 50%,
                rgba(255, 255, 255) 100%
            );
        }
        .write-btn {
            display: flex;
            height: 40px;
            width: 152px;
            background: transparent;
            color: #fff;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            color: #24c68b;
            padding-left: 24px;
            background: linear-gradient(270deg, rgba(252, 252, 252, 0.3) 0%, #f7f7f7 100%);
            cursor: pointer;
            img {
                display: block;
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }
        .item-box {
            position: relative;
            margin-top: 30px;
            &:last-of-type::after {
                display: none;
            }
        }
        .title {
            font-size: 18px;
            font-weight: 400;
            color: #000000;
            display: flex;
            align-items: center;
            width: 152px;
            justify-content: center;
            margin-bottom: 10px;
            img {
                width: 20px;
                height: 20px;
                display: block;
                margin-right: 10px;
            }
        }
        .item {
            padding-left: 13px;
            box-sizing: border-box;
            width: 152px;
            height: 46px;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: all 0.5s;
            padding-left: 40px;
            font-size: 16px;
            font-weight: 400;
            color: #222222;
            position: relative;
            .dot {
                content: "";
                position: absolute;
                right: 20px;
                top: 10px;
                border: 2px solid #ffffff;
                border-radius: 100%;
                height: 10px;
                width: 10px;
                background-color: #ff4739;
                box-sizing: border-box;
            }
            i {
                margin-right: 12px;
            }
            &.active {
                transition: all 0.5s;
                /*background: #e9f9f3;*/
                color: #24c68b;
            }
        }
    }
    .right {
        min-height: calc(100vh - 64px);
        height: fit-content;
        // background-color: #fff;
        width: 100%;
        border-radius: 10px 10px 0px 0px;
        border-bottom: 20px solid #f7f7f7;
    }
}
</style>
