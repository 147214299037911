<template>
    <div class="ku-main-layout">
        <div class="ku-main-layout-header">
            <div class="ku-main-layout-bg"></div>
            <div class="ku-main-layout-nav">
                <ku-navbar></ku-navbar>
            </div>
        </div>
        <div class="ku-main-layout-body">
            <KeepAlive include="home-view">
                <router-view :key="$route.fullPath"></router-view>
            </KeepAlive>
        </div>
        <div
            class="ku-main-layout-footer"
            :style="{
                height: showFooter ? '1px' : '260px',
                overflow: 'hidden',
                opacity: showFooter ? 0 : 1,
            }"
        >
            <div class="ku-main-layout-footer-body">
                <div class="ku-main-layout-footer-top">
                    <div class="ku-main-layout-footer-top-item ku-main-layout-footer-top-item-first">
                        <p class="ku-main-layout-text">西安库学长信息技术有限公司</p>
                        <p class="ku-main-layout-text">客服热线：</p>
                        <p class="ku-main-layout-value-phone">029 8821 8621</p>
                        <p class="ku-main-layout-text">公司地址：</p>
                        <p class="ku-main-layout-value">陕西省西安市高新区沣惠南路34号新长安广场c座11层11106室</p>
                    </div>
                    <div class="ku-main-layout-footer-top-item ku-main-layout-footer-top-item-secd">
                        <p class="ku-main-layout-text private-text" @click.stop="gotoPrivate('private')">隐私协议</p>
                        <p class="ku-main-layout-text private-text" @click.stop="gotoPrivate('service')">服务条款</p>
                    </div>
                    <div class="ku-main-layout-footer-top-item ku-main-layout-footer-top-item-flex">
                        <div class="ku-main-layout-weixin-item">
                            <img src="@/assets/w3-footer.jpg" />
                            <div>添加客服微信</div>
                            <div>了解更多内容</div>
                        </div>
                        <div class="ku-main-layout-weixin-item">
                            <img class="ku-main-layout-weixin-cycle" src="@/assets/public.jpg" />
                            <div>扫码关注</div>
                            <div>KU学长公众号</div>
                        </div>
                    </div>
                </div>
                <div class="ku-main-layout-footer-bottom">
                    西安库学长信息技术有限公司
                    <a class="ku-main-layout-footer-gov" href="https://beian.miit.gov.cn/" target="_blank">
                        陕ICP备2023009067号-1
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KuNavbar from "@/components/ku-navbar/ku-navbar"

export default {
    name: "ku-main-layout",
    components: { KuNavbar },
    data() {
        return {
            isCreatePage: false,
        }
    },
    computed:{
        showFooter(){
            let arr = ['articleList','publishtrend','messagelist']
            let _index = arr.indexOf(this.$route.name)
            return _index!==-1
        }
    },
    methods: {
        gotoPrivate(path) {
            const route = this.$router.resolve({
                path: `/${path}`,
            })
            window.open(route.href)
        },
    },
}
</script>

<style lang="scss" scoped>
.ku-main-layout {
    background-color: #f7f7f7;

    .ku-main-layout-bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #243d41;
    }

    .ku-main-layout-header {
        position: relative;
        height: 64px;
    }

    .ku-main-layout-nav {
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;
    }

    .ku-main-layout-body {
        min-height: calc(100vh - 64px);
    }

    .ku-main-layout-footer {
        background: #313438;
    }

    .ku-main-layout-footer-body {
        width: 1200px;
        margin: 0 auto;
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .ku-main-layout-footer-top {
        display: flex;
        padding-bottom: 30px;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    }

    .ku-main-layout-footer-top-item-flex {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ku-main-layout-weixin-item {
        width: 100px;
        text-align: center;

        img {
            width: 100px;
            height: 100px;
        }
    }

    .ku-main-layout-value-phone {
        margin-bottom: 20px;
    }

    .ku-main-layout-weixin-item + .ku-main-layout-weixin-item {
        margin-left: 100px;
    }

    .ku-main-layout-footer-top-item-first {
        margin-right: 118px;
    }

    .ku-main-layout-footer-top-item-secd {
        margin-right: 338px;
    }

    .ku-main-layout-text {
        white-space: nowrap;
    }

    .ku-main-layout-text + .ku-main-layout-text {
        margin-top: 20px;
    }

    .ku-main-layout-weixin-cycle {
        /*border-radius: 50px;*/
    }

    .private-text {
        cursor: pointer;
    }

    .ku-main-layout-footer-bottom {
        color: #939496;
    }

    .ku-main-layout-footer-gov {
        font-size: 14px;
        font-weight: 400;
        color: #939496;
    }
}
</style>
