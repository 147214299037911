import { login, logout, getuserInfoSimple } from "@/api/login" //getInfo
import { getToken, setToken, removeToken } from "@/utils/auth"
import defaultPhoto from "@/assets/defaultphoto.png"
const user = {
    state: {
        token: getToken(),
        userInfo: {},
        isShowLoginFrom: false,
        isShowRegistFrom: false,
        avatar: defaultPhoto,
        isWrite: false,
        isHaveMassege:false,
        count:{},
        paySuccess:'',
        companyRecommendPostId:''
    },
    mutations: {
        SET_COMPANY_RECOMMEND_POST_ID: (state, companyRecommendPostId) => {
            state.companyRecommendPostId = companyRecommendPostId
        },
        SET_PAYSUCCESS: (state, paySuccess) => {
            state.paySuccess = paySuccess
        },
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_COUNT: (state, count) => {
            state.count = count
        },
        SET_ISWRITE: (state, isWrite) => {
            state.isWrite = isWrite
        },
        SET_USERINFO: (state, info) => {
            state.userInfo = info
        },
        SET_SHOWLOGIN: (state, isShow) => {
            state.isShowLoginFrom = isShow
        },
        SET_SHOWREGIST: (state, isShow) => {
            state.isShowRegistFrom = isShow
        },
        SET_ISHAVEMESSAGE: (state, isHaveMassege) => {
            state.isHaveMassege = isHaveMassege
        },
        SET_AVATAR: (state, url) => {
            if (url) {
                state.avatar = url
                state.userInfo.headImage = url
            } else {
                state.avatar = defaultPhoto
                state.userInfo.headImage = defaultPhoto
            }
        },
    },
    actions: {
        // 登录
        Login({ commit }, userInfo) {
            const phone = userInfo.phone.trim()
            const code = userInfo.code
            sessionStorage.removeItem("currentActive")
            return new Promise((resolve, reject) => {
                login({ phone, code })
                    .then(res => {
                        sessionStorage.setItem("currentActive", "2")
                        const { access_token, isFirstLogin } = res.data
                        setToken(access_token)
                        commit("SET_TOKEN", access_token)
                        commit("SET_SHOWREGIST", isFirstLogin)
                        resolve(res.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        // 获取用户信息
        GetInfo({ commit }) {
            return new Promise((resolve, reject) => {
                commit("SET_AVATAR", "")
                getuserInfoSimple()
                    .then(resp => {
                        const { headImage = "",phone='' } = resp.data || {}
                        commit("SET_USERINFO", { ...resp.data, headImage ,phone} || {})
                        commit("SET_AVATAR", headImage || "")
                        if(phone){
                          localStorage.setItem('phone',phone)
                        }
                        resolve(resp.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        // 退出系统
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
                logout(state.token)
                    .then(() => {
                        commit("SET_TOKEN", "")
                        commit("SET_USERINFO", {})
                        removeToken()
                        resolve()
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        // 前端 登出
        FedLogOut({ commit }) {
            return new Promise(resolve => {
                commit("SET_TOKEN", "")
                commit("SET_USERINFO", {})
                removeToken()
                resolve()
            })
        },
        setIsWrites({ commit }, isWrite) {
            commit("SET_ISWRITE", isWrite)
        },
        setIsHaveMessage({ commit }, isHaveMassege) {
            commit("SET_ISHAVEMESSAGE", isHaveMassege)
        },
        setCount({ commit }, count={}) {
            commit("SET_COUNT", count)
        },
        setPaySuccess({ commit }, paySuccess='') {
            commit("SET_PAYSUCCESS", paySuccess)
        },
        setCompanyRecommendPostId({ commit }, companyRecommendPostId='') {
            commit("SET_COMPANY_RECOMMEND_POST_ID", companyRecommendPostId)
        },
        
    },
}

export default user
