<template>
    <div class="ku-forum-content">
        <div class="ku-article-center" style="width: calc(1200px - 180px - 300px)">
            <div class="ku-article-body">
                <div class="ku-article-search">
                    <div class="ku-article-search-input">
                        <el-input style="width: 100%" clearable placeholder="请输入想看的内容标题" v-model="query.title"
                            class="input-with-select-input" @change="delayChange">
                            <el-button class="input-with-select-btn-input" slot="append" @click="delayChange">
                                <i class="el-icon-search"></i>
                                搜索
                            </el-button>
                        </el-input>
                    </div>
                    <div class="ku-article-search-tabs" v-if="topics.length">
                        <el-tabs type="card" v-model="query.sectionId" @tab-click="topicsTabClick">
                            <el-tab-pane label="全部" name="0"></el-tab-pane>
                            <el-tab-pane v-for="item in topics" :key="item.sectionId" :label="item.sectionName"
                                :name="item.sectionId"></el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
                <div class="ku-article-filter" v-if="query.topicType != 99" style="display: none;">
                    <div class="ku-article-search-box" v-if="query.topicType == 0">
                        <el-select class="ku-article-search-select" v-model="query.postId" placeholder="请输入岗位名称" filterable
                            clearable remote reserve-keyword style="width: 210px; height: 34px" :remote-method="queryPost"
                            @change="change">
                            <el-option v-for="item in postOpts" :key="item.postId" :label="item.postName"
                                :value="item.postId" />
                        </el-select>
                        <el-select class="ku-article-search-select-right" v-model="query.companyId" placeholder="请输入公司名称"
                            filterable clearable remote reserve-keyword style="width: 210px; height: 34px"
                            :remote-method="queryCompany" @change="change">
                            <el-option v-for="item in companyOpts" :key="item.companyId" :label="item.companyName"
                                :value="item.companyId" />
                        </el-select>
                    </div>
                    <el-tabs v-model="indicator" @tab-click="indicatorTabClick">
                        <el-tab-pane label="最新" name="2" :disabled="isLoading"></el-tab-pane>
                        <el-tab-pane label="推荐" name="1" :disabled="isLoading"></el-tab-pane>
                        <!-- <el-tab-pane v-if="token" label="收藏" name="3" :disabled="isLoading"></el-tab-pane> -->
                    </el-tabs>
                </div>
            </div>
            <ku-article-card :post-item="list" @go-detail="goDetail" @do-remove="doRemove"></ku-article-card>
            <div class="work-detail-empty-article" v-show="isEmpty || isLoading">
                <img class="work-detail-empty-img" src="@/assets/nodata/queshengye.png" />
                <div class="work-detail-empty-text">{{ isLoading ? "数据加载中" : "暂无数据" }}</div>
            </div>
        </div>
        <div class="ku-article-right">
            <div class="ku-article-hot">
                <div class="ku-article-hot-section">
                    <ku-article-create :post-draft="articleNum" :post-like="count.like" :post-collect="count.collect"
                        :unread-count="count"></ku-article-create>
                </div>
                <div class="ku-article-hot-section" v-if="hotList.length">
                    <ku-article-hot :post-items="hotList" @go-detail="goDetail"></ku-article-hot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gengduo from "@/assets/article/gengduo.png"
import mianjing from "@/assets/article/mianjing.png"
import richang from "@/assets/article/richang.png"
import xiangmu from "@/assets/article/xiangmu.png"
import xuexi from "@/assets/article/xuexi.png"
import shijianzhou from "@/assets/article/shijianzhou.png"
import shoucang from "@/assets/article/shoucang.png"
import gengduoactive from "@/assets/article/gengduo-active.png"
import mianjingactive from "@/assets/article/mianjing-active.png"
import richangactive from "@/assets/article/richang-active.png"
import xiangmuactive from "@/assets/article/xiangmu-active.png"
import xuexiactive from "@/assets/article/xuexi-active.png"
import shijianzhouactive from "@/assets/article/shijianzhou-active.png"
import shoucangactive from "@/assets/article/shoucang-active.png"
import { mapGetters } from "vuex"
import KuArticleCreate from "@/views/ku-article/ku-article-create"
import KuArticleHot from "@/views/ku-article/ku-article-hot"
import KuArticleCard from "@/views/ku-article/ku-article-card"
import KuArticleTimeline from "@/views/ku-article/ku-article-timeline"
import { getUserUnreadMsgNum } from "@/api/user"
import {
    getTopicTypeList,
    getRecentList,
    getHotList,
    getCollectList,
    getTopicTypes,
    getTopList,
    getArticleCount,
} from "@/api/article"
import { searchMajor, searchCompany } from "@/api/request"
import { getArticleNum } from "@/api/bbs"
import throttle from "throttle-debounce/throttle"
export default {
    name: "ku-forum-content",
    components: { KuArticleCreate, KuArticleHot, KuArticleCard, KuArticleTimeline },
    data() {
        const { query, params } = this.$route
        const { companyName, companyId } = query
        const { id: topicId } = params
        const companyOpts = []
        if (companyName && companyId) {
            companyOpts.push({ companyName, companyId })
        }
        return {
            isLoading: false,
            companyOpts,
            postOpts: [],
            topics: [],
            indicator: "2",
            query: {
                pageNum: 1,
                pageSize: 10,
                schoolId: "",
                companyId: companyId ? companyId : "",
                postId: "",
                topicType: topicId || 0,
                title: "",
                sectionUnionId: "0",
                sectionId: "0",
            },
            leftTopics: [],
            list: [],
            hotList: [],
            articleNum: 0,
            emptyText: "暂无数据",
            count: {},
            unReadCount: {},
            isFinished: false,
            total: 0
        }
    },
    computed: {
        ...mapGetters(["token"]),
        isEmpty() {
            return this.list.length == 0 && this.total <= 0
        },
    },
    created() {
        this.getArticleCountFun()
        this.getTopicList()
        this.active = this.query.topicType
        if (this.query.topicType != 100) this.getList(this.query)
        if (this.query.topicType == 0) {
            this.getTopics()
        }
        this.getHotSepList()
        if (this.token) {
            this.getArticleNumFun()
        }
    },
    mounted() {
        this.init()
        this.throttledScrollHandler = throttle(300, this.onScroll)
        this.container.addEventListener("scroll", this.throttledScrollHandler)
    },
    beforeDestroy() {
        this.container.removeEventListener("scroll", this.throttledScrollHandler)
    },
    methods: {
        getArticleCountFun() {
            getArticleCount().then(res => {
                this.count = res.data
            })
            // 下面接口后端说不用了
            // getUserUnreadMsgNum().then(res => {
            //     this.unReadCount = res.data
            // })
        },
        doRemove(row) {
            const index = this.list.indexOf(row)
            const { topicType } = this.query
            if (index > -1 && topicType == 99) {
                this.list.splice(index, 1)
                this.total--
            }
        },
        // 跳转至详情页面
        goDetail(row) {
            let topicType = ""
            !row.topicType && row.topicType !== 0 ? (topicType = "") : (topicType = row.topicType)
            const { href } = this.$router.resolve(`/bbs/detail/${row.topicId}/${topicType}`)
            window.open(href)
        },
        delayChange() {
            window.clearTimeout(this.timer)
            this.timer = window.setTimeout(() => {
                this.change()
            }, 300)
        },
        getArticleNumFun() {
            return getArticleNum().then(res => {
                this.articleNum = res.data.draft || 0
            })
        },
        go2Detail(row) {
            const { href } = this.$router.resolve(`/bbs/detail/${row.topicId}/${this.query.topicType}`)
            window.open(href)
        },
        init() {
            this.container = document
            this.el = document.documentElement
        },
        isReachBottom() {
            const currentTop = this.el.scrollTop
            if (currentTop - this.lastTop > 0) {
                // down
                console.log(this.el.scrollHeight - 600 <= this.el.scrollTop + this.el.clientHeight);
                return this.el.scrollHeight - 600 <= this.el.scrollTop + this.el.clientHeight
            }
            this.lastTop = currentTop
            return false
        },
        onScroll() {
            if (this.isFinished) {
                this.isLoading = false
                return;
            }
            if (this.isLoading) {
                return
            }
            // const isReachBottom = this.el.scrollHeight - 600 <= this.el.scrollTop + this.el.clientHeight
            if (this.isReachBottom()) {
                this.isLoading = true
                this.getList(this.query)
            }
        },
        indicatorTabClick() {
            this.list = []
            this.total = 0
            this.query.pageNum = 1
            this.isLoading = true // 禁用滚动调
            this.el.scrollTop = 0
            this.isFinished = false
            return this.getList(this.query)
        },
        queryCompany(text) {
            window.clearTimeout(this.timer)
            this.timer = window.setTimeout(() => {
                this.getCompany(text)
            }, 300)
        },
        queryPost(text) {
            window.clearTimeout(this.timer)
            this.timer = window.setTimeout(() => {
                this.getPost(text)
            }, 300)
        },
        getPost(text) {
            return searchMajor(text).then(resp => {
                this.postOpts = resp.data || []
            })
        },
        getCompany(text) {
            return searchCompany(text).then(resp => {
                this.companyOpts = resp || []
            })
        },
        topicsTabClick() {
            const { sectionId } = this.query
            const [result] = this.topics.filter(item => item.sectionId == sectionId)
            this.list = []
            this.total = 0
            this.query.pageNum = 1
            this.isFinished = false
            if (result) {
                this.query.sectionUnionId = result.unionId
            } else {
                this.query.sectionId = "0"
                this.query.sectionUnionId = "0"
            }
            this.isLoading = true // 禁用滚动调
            this.el.scrollTop = 0
            return this.getList(this.query)
        },
        go2Topic(topicId) {
            this.active = topicId
            this.query.topicType = topicId
            this.$router.push({
                path: "/reload",
                query: {
                    redirect: "/bbs/index",
                    topicId,
                },
            })
        },
        getHotSepList() {
            return getTopList().then(resp => {
                this.hotList = resp.data
            })
        },
        getList(query) {
            if (this.total < this.query.pageSize && this.list.length) {
                this.isLoading = false
                return Promise.resolve(false)
            }
            this.isLoading = true
            const indicator = this.indicator
            const { topicType } = this.query
            if (topicType == 99) {
                return this.getStoreList(query).finally(() => {
                    this.isLoading = false
                    if (!this.list.length) {
                        this.emptyText = "暂无数据"
                    }
                })
            }
            if (indicator == 1) {
                return this.getHotList(query).finally(() => {
                    this.isLoading = false
                    if (!this.list.length) {
                        this.emptyText = "暂无数据"
                    }
                })
            }
            if (indicator == 2) {
                return this.getNewList(query).finally(() => {
                    this.isLoading = false
                    if (!this.list.length) {
                        this.emptyText = "暂无数据"
                    }
                })
            }
            // if (indicator == 3) {
            //     return this.getStoreList(query).finally(() => {
            //         this.isLoading = false
            //         if (!this.list.length) {
            //             this.emptyText = "暂无数据"
            //         }
            //     })
            // }
        },
        // getList4Stroe(params) {
        //     return getStoreList(params).then(resp => {
        //         const { rows = [] } = resp || {}
        //         this.list = this.list.concat(rows)
        //         this.total = rows.length
        //         this.query.pageNum++
        //     })
        // },
        getHotList(query) {
            return getHotList(query).then(resp => {
                const { rows } = resp
                if (rows.length > 0) {
                    this.total = rows.length
                    this.list = this.list.concat(rows)
                    this.query.pageNum++
                } else {
                    this.isFinished = true;
                }
            })
        },
        getNewList(query) {
            return getRecentList(query).then(resp => {
                const { rows } = resp
                if (rows.length > 0) {
                    this.total = rows.length
                    this.list = this.list.concat(rows)
                    this.query.pageNum++
                } else {
                    this.isFinished = true;
                }
            })
        },
        getStoreList(query) {
            return getCollectList(query).then(resp => {
                const { rows } = resp
                if (rows.length > 0) {
                    this.total = rows.length
                    this.list = this.list.concat(rows)
                    this.list.map(element => {
                        if (!element.briefContent&&(!element.imageList||element.imageList.length===0)) {
                            element.collectCount = 1
                            element.collect = true
                        }
                    })
                    this.query.pageNum++
                } else {
                    this.isFinished = true;
                }
            })
        },
        getTopics() {
            return getTopicTypes(this.query.topicType).then(resp => {
                this.topics = resp.data
            })
        },
        getTopicList() {
            return getTopicTypeList().then(resp => {
                // topicType  topicTypeName
                this.leftTopics = (resp.data || []).map(item => {
                    const { topicType, topicTypeName } = item
                    if (topicType == 100) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: shijianzhou,
                            active: shijianzhouactive,
                        }
                    }
                    if (topicType == 0) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: mianjing,
                            active: mianjingactive,
                        }
                    }
                    if (topicType == 1) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: xuexi,
                            active: xuexiactive,
                        }
                    }
                    if (topicType == 2) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: xiangmu,
                            active: xiangmuactive,
                        }
                    }
                    if (topicType == 3) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: richang,
                            active: richangactive,
                        }
                    }
                    if (topicType == 4) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: gengduo,
                            active: gengduoactive,
                        }
                    }
                    if (topicType == 5) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: gengduo,
                            active: gengduoactive,
                        }
                    }
                    if (topicType == 99) {
                        return {
                            name: topicTypeName,
                            id: topicType,
                            icon: shoucang,
                            active: shoucangactive,
                        }
                    }
                    return {
                        name: topicTypeName,
                        id: topicType,
                        icon: gengduo,
                        active: gengduoactive,
                    }
                })
            })
        },
        change() {
            this.query.pageNum = 1
            this.total = 0
            this.list = []
            this.el.scrollTop = 0
            this.isLoading = true
            this.$nextTick(() => {
                this.isFinished = false
                this.getList(this.query)
            })
        },
    },
}
</script>

<style lang="scss">
.work-detail-empty-article {
    margin-top: 20px;

    img {
        width: 538px;
        height: 305px;
    }
}

.ku-article-search-input {
    .el-input__inner {
        height: 34px;
        border: none;
        background-color: #f7f7f7;
    }

    .el-input-group__append {
        .input-with-select-btn-input {
            width: 88px;
            height: 34px;
            background: #24c68b;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            padding: 0;
        }
    }
}

.work-detail-empty-article {
    text-align: center;
    background: #fff;
    padding: 60px 0;
    font-size: 16px;
    font-weight: 500;
    color: #999999;
    border-radius: 10px;
    margin-left: 20px;
}

.ku-article-search-box {
    margin-bottom: 20px;
    display: flex;

    .el-input__inner {
        height: 35px;
    }

    .el-icon-circle-close {
        height: 35px;
        line-height: 35px;
    }

    .ku-article-search-select {
        margin-right: 20px;
    }

    .ku-article-search-select-right {
        margin-left: auto;
        margin-right: 0;
    }
}

.back-top-wraper {
    .el-backtop {
        width: 55px !important;
        height: 55px !important;
    }

    .back-top-image {
        width: 55px;
        height: 55px;
        border-radius: 55px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.ku-forum-content {
    display: flex;

    .ku-article-left {
        width: 180px;
        border-radius: 10px;
        flex: none;
    }

    .ku-article-center {
        flex: 1;
        border-radius: 10px;
    }

    .ku-article-right {
        width: 300px;
        margin-left: auto;
        margin-right: 0;
        border-radius: 10px;
    }
}

.ku-article-bread-wraper {
    overflow: hidden;
    background-color: #fff;
    border-radius: 10px;
}

.ku-article-bread {
    margin: 16px 7px 16px 9px;

    .ku-article-bread-item {
        display: flex;
        align-items: center;
        padding: 11px 17px;
        cursor: pointer;
        border-radius: 5px;
    }

    .ku-article-bread-item-icon {
        width: 16px;
        height: 16px;
        margin-right: 15px;

        img {
            width: 100%;
            height: 100%;
            vertical-align: top;
        }
    }

    .ku-article-bread-item-text {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        white-space: nowrap;
    }

    .ku-article-bread-item:hover {
        background: #f7f7f7;
    }

    .ku-article-bread-item.active {
        background: #e9f9f3;

        .ku-article-bread-item-text {
            color: #24c68b;
        }
    }
}

.ku-article-search-tabs {
    margin-top: 20px;

    .el-tabs__content {
        display: none !important;
    }

    .el-tabs__item {
        padding: 0 16px;
        height: auto;
        line-height: normal;
        border: none !important;
        background: #f7f7f7;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        margin-right: 20px;
        padding: 2px 12px !important;
    }

    .el-tabs__item.is-active {
        background: #24c68b;
        font-size: 14px;
        color: #ffffff;
    }

    .el-tabs__header {
        border-bottom: none !important;
        margin-bottom: 0;
    }

    .el-tabs__nav {
        border: none !important;
    }

    .el-tabs__nav-next,
    .el-tabs__nav-prev {
        line-height: normal;
    }
}

.ku-article-filter {
    .el-tabs__content {
        display: none !important;
    }

    .el-tabs__header {
        margin-bottom: 0;
    }

    .el-tabs__nav-wrap::after {
        display: none !important;
    }

    .el-tabs__item {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
    }

    .el-tabs__item.is-active {
        font-size: 16px;
        font-weight: 600;
        color: #24c68b;
    }

    .el-tabs__active-bar {
        background-color: #24c68b;
    }
}

.ku-article-search {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.ku-article-filter {
    margin: 14px 0;
    background-color: #fff;
    padding: 14px 24px 4px;
    border-radius: 10px;
}

.ku-article-body {
    margin-left: 20px;
    margin-bottom: 20px;
}

.ku-article-hot {
    margin-left: 20px;

    .ku-article-hot-section {
        margin-bottom: 14px;
    }
}

.flex {
    display: flex;
}
</style>
