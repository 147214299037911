var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ku-navbar"},[_c('el-row',{attrs:{"gutter":0}},[_c('el-col',{attrs:{"span":17}},[_c('div',{staticClass:"ku-navbar-menus button"},[_c('img',{staticClass:"ku-navbar-logo",attrs:{"src":require("@/assets/icons/kuxuezhanglogo.png")},on:{"click":_vm.goIndex}}),_c('el-menu',{staticClass:"ku-navbar-menu",attrs:{"router":true,"active-text-color":"#24C68B","text-color":"#fff","background-color":"transparent","default-active":_vm.activeMenu,"mode":"horizontal"}},[_c('el-menu-item',{directives:[{name:"track-event",rawName:"v-track-event.click",value:('顶部导航,点击,找学长'),expression:"'顶部导航,点击,找学长'",modifiers:{"click":true}}],attrs:{"index":"/ship"}},[_vm._v("找学长")]),_c('el-menu-item',{directives:[{name:"track-event",rawName:"v-track-event.click",value:('顶部导航,点击,找校招'),expression:"'顶部导航,点击,找校招'",modifiers:{"click":true}}],attrs:{"index":"/index"}},[_vm._v("找校招")])],1)],1)]),(_vm.token)?_c('el-col',{attrs:{"span":7}},[_c('div',{staticClass:"ku-navbar-user-infos"},[(!_vm.isWrite)?_c('div',{class:_vm.count.msgCount > 0 || _vm.count.commentMsgCount > 0 || _vm.count.likeMsgCount > 0
                            ? 'create-box button active'
                            : 'create-box button'}):_vm._e(),_c('div',{staticClass:"ku-navbar-users button"},[_c('div',{staticStyle:{"height":"24px"},on:{"click":_vm.goCenter}},[_c('el-avatar',{attrs:{"size":24,"src":_vm.avatar}})],1),_c('el-dropdown',{on:{"command":_vm.handleCommand}},[_c('div',{staticClass:"ku-navbar-name ellipsis"},[_vm._v(" "+_vm._s(_vm.userInfo.nickName)+" ")]),_c('el-dropdown-menu',{staticClass:"ku-navbar-menu-wraper",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":{ type: 'router', path: '/info' }}},[_c('div',{staticClass:"ku-navabar-item"},[_c('span',{staticClass:"ku-navbar-text"},[_vm._v("个人主页")])])]),_c('el-dropdown-item',{attrs:{"command":{
                                    type: 'router',
                                    path: '/index',
                                    query: { active: 2, tab: 'delivery', time: new Date().getTime() },
                                }}},[_c('div',{staticClass:"ku-navabar-item"},[_c('span',{staticClass:"ku-navbar-text"},[_vm._v("投递记录")])])]),_c('el-dropdown-item',{attrs:{"command":{
                                    type: 'router',
                                    path: '/index',
                                    query: { active: 2, tab: 'collection', time: new Date().getTime() },
                                }}},[_c('div',{staticClass:"ku-navabar-item"},[_c('span',{staticClass:"ku-navbar-text"},[_vm._v("我的收藏")])])]),_c('el-dropdown-item',[_c('div',{staticClass:"ku-navabar-item"},[_c('span',{staticClass:"ku-navbar-text",on:{"click":_vm.logoutHanlder}},[_vm._v("退出登录")])])])],1)],1)],1)])]):_c('el-col',{attrs:{"span":5}},[_c('div',{staticClass:"ku-navbar-user-infos"},[_c('div',{staticClass:"create-box button"}),_c('div',{staticClass:"ku-navbar-users button"},[_c('span',{staticClass:"ku-navbar-login-btn",on:{"click":_vm.showLoginDialog}},[_vm._v("登录 | 注册")])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }