var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ku-forum-layout"},[_c('div',{staticClass:"ku-forum-layout-header"},[_c('div',{staticClass:"ku-forum-layout-bg"}),_c('div',{staticClass:"ku-forum-layout-nav"},[_c('ku-navbar')],1)]),_c('div',{staticClass:"ku-forum-layout-body"},[_c('div',{staticClass:"ku-forum-layout-body-section"},[_c('div',{staticClass:"ku-forum-layout-body-left"},[_c('el-menu',{staticClass:"ku-forum-layout-body-menu",attrs:{"router":true,"default-active":_vm.activeMenu}},_vm._l((_vm.leftTopics),function(item){return _c('el-menu-item',{directives:[{name:"track-event",rawName:"v-track-event.click",value:('论坛,' + item.name + ',点击'),expression:"'论坛,' + item.name + ',点击'",modifiers:{"click":true}}],key:item.id,staticClass:"ku-article-bread-item",attrs:{"index":item.path}},[(item.path == _vm.activeMenu)?_c('div',{staticClass:"ku-article-bread-item-icon"},[_c('img',{attrs:{"src":item.active}})]):_c('div',{staticClass:"ku-article-bread-item-icon"},[_c('img',{attrs:{"src":item.icon}})]),_c('div',{staticClass:"ku-article-bread-item-text"},[_vm._v(_vm._s(item.name))])])}),1)],1),_c('div',{staticClass:"ku-forum-layout-body-right"},[_c('KeepAlive',{attrs:{"include":"home-view"}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)])]),_c('div',{staticClass:"ku-forum-layout-footer",style:({
                    height: _vm.$route.name == 'articleList' ? '1px' : '260px',
                    overflow: 'hidden',
                    opacity: _vm.$route.name == 'articleList' ? 0 : 1,
                })},[_c('div',{staticClass:"ku-forum-layout-footer-body"},[_c('div',{staticClass:"ku-forum-layout-footer-top"},[_vm._m(0),_c('div',{staticClass:"ku-forum-layout-footer-top-item ku-forum-layout-footer-top-item-secd"},[_c('p',{staticClass:"ku-forum-layout-text private-text",on:{"click":function($event){$event.stopPropagation();return _vm.gotoPrivate('private')}}},[_vm._v("隐私协议")]),_c('p',{staticClass:"ku-forum-layout-text private-text",on:{"click":function($event){$event.stopPropagation();return _vm.gotoPrivate('service')}}},[_vm._v("服务条款")])]),_vm._m(1)]),_vm._m(2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ku-forum-layout-footer-top-item ku-forum-layout-footer-top-item-first"},[_c('p',{staticClass:"ku-forum-layout-text"},[_vm._v("西安库学长信息技术有限公司")]),_c('p',{staticClass:"ku-forum-layout-text"},[_vm._v("客服热线：")]),_c('p',{staticClass:"ku-forum-layout-value-phone"},[_vm._v("029 8821 8621")]),_c('p',{staticClass:"ku-forum-layout-text"},[_vm._v("公司地址：")]),_c('p',{staticClass:"ku-forum-layout-value"},[_vm._v("陕西省西安市高新区沣惠南路34号新长安广场c座11层11106室")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ku-forum-layout-footer-top-item ku-forum-layout-footer-top-item-flex"},[_c('div',{staticClass:"ku-forum-layout-weixin-item"},[_c('img',{attrs:{"src":require("@/assets/w3-footer.jpg")}}),_c('div',[_vm._v("添加客服微信")]),_c('div',[_vm._v("了解更多内容")])]),_c('div',{staticClass:"ku-forum-layout-weixin-item"},[_c('img',{staticClass:"ku-forum-layout-weixin-cycle",attrs:{"src":require("@/assets/public.jpg")}}),_c('div',[_vm._v("扫码关注")]),_c('div',[_vm._v("KU学长公众号")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ku-forum-layout-footer-bottom"},[_vm._v(" 西安库学长信息技术有限公司 "),_c('a',{staticClass:"ku-forum-layout-footer-gov",attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v(" 陕ICP备2023009067号-1 ")])])
}]

export { render, staticRenderFns }